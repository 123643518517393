import React from 'react'
import Layout from '../components/Layout/Layout'
import CooperationForm from '../components/CooperationForm/CooperationForm'
import { graphql } from 'gatsby'

const CooperationFormPage = ({ data }) => {
  const { strapiCompanyInfo: { phoneNumber }, strapiCooperationForm } = data

  return <Layout description='Kooperationen formular'>
    <CooperationForm {...strapiCooperationForm} phoneNumber={phoneNumber} />
  </Layout>
}
export default CooperationFormPage

export const query = graphql`
  query CooperationFormPageQuery {
  ...cooperationFormFragment
  ...companyInfoFragment
  }
 `
