import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import FormWrapper from '../FormWrapper/FormWrapper'
import CooperationFormLeftSide from './CooperationFormLeftSide'
import PropTypes from 'prop-types'
import { StrapiImageSharpPropType } from '../propTypes'
import JotformEmbed from 'react-jotform-embed'

const CooperationForm = ({ header, headline, description, image, name, position, phoneNumber, email }) =>
  <SectionWrapper className='cooperation-form-container'>
    <div className='cooperation-form-container__header'>{header}</div>
    <div className='cooperation-form-container__headline'>{headline}</div>
    <div className='cooperation-form-container__description'>{description}</div>
    <FormWrapper
      leftSide={
        <CooperationFormLeftSide
          image={image}
          name={name}
          position={position}
          phoneNumber={phoneNumber}
          email={email}
        />}
      rightSide={<JotformEmbed src='https://form.jotform.com/220446630060343' />}
    />
  </SectionWrapper>

export default CooperationForm

CooperationForm.propTypes = {
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string
}

CooperationForm.defaultProps = {
  header: '',
  headline: '',
  description: '',
  image: {},
  name: '',
  position: '',
  phoneNumber: '',
  email: ''
}
