import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { StrapiImageSharpPropType } from '../propTypes'

const CooperationFormLeftSide = ({ image, name, position, phoneNumber, email }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <div className='cooperation-form-left-side'>
    <GatsbyImage
      image={gatsbyImageData}
      alt={image?.alternativeText}
      className='cooperation-form-left-side__image'
    />
    <div className='cooperation-form-left-side__name'>{name}</div>
    <div className='cooperation-form-left-side__position'>{position}</div>
    <a
      href={`tel:${phoneNumber}`}
      className='cooperation-form-left-side__phone-number'
    >
      Tel:. {phoneNumber}
    </a>
    <div className='cooperation-form-left-side__email'>E-Mail: {email}</div>
  </div>
}

export default CooperationFormLeftSide

CooperationFormLeftSide.propTypes = {
  image: StrapiImageSharpPropType,
  name: PropTypes.string,
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string
}

CooperationFormLeftSide.defaultProps = {
  image: {},
  name: '',
  position: '',
  phoneNumber: '',
  email: ''
}
